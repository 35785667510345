import React from "react"
import { Link } from "gatsby"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

import NewsHeader from "./NewsHeader"
import Lonform from "../longform/Longform"
import WorkRelated from "../work/WorkRelated"
import HtmlParser from "../../components/htmlParser"
import Sharer from "../../components/Sharer"

const textColumnClassName = `news__single__body entry col-11 col-md-9 col-lg-7 col-xl-6 mx-auto no-padding`

class NewsSingleComponent extends React.Component {
  // state = {}

  headerLink() {
    // console.log(this.props)
    if (this.props.section) {
      return <Link to={`/${this.props.section}/`}>{this.props.section}</Link>
    } else {
      return []
    }
  }

  render() {
    let {
      title,
      subheadline,
      content,
      mainImage,
      longform,
      // relatedWork,
      seoFields,
    } = this.props.data.news
    // console.log(this.props)
    let shareImage
    if(mainImage.gatsbyImageData !== null){
     shareImage = mainImage.gatsbyImageData.images.fallback ? mainImage.gatsbyImageData.images.fallback.src : mainImage.gatsbyImageData.images.sources[0].srcSet; 
    }
    else{
      shareImage = null
    }
    return (
      <Layout>
        <SEO 
          title={title} 
          description={subheadline} 
          seoFields={seoFields} 
          image={mainImage ? shareImage : null} 
          bodyClass={this.constructor.name} 
        />
      <section className="news__single">

        <header className="single__header">
          <p className="h4 text-center">{this.headerLink()}</p>
        </header>

        <div className="row">
          <NewsHeader
            news={this.props.data.news}
            isSingle={true}
            bottom={true}
          />
        </div>

        {content && (
          <div className="container-fluid">
            <div className="row">
              <div className={textColumnClassName}>
                <HtmlParser
                  key={new Date()}
                  html={content.childMarkdownRemark.html}
                />
              </div>
            </div>
          </div>
        )}

        <Lonform rows={longform} textColumnClassName={textColumnClassName} />

        <Sharer title={this.props.data.news.title} />

        {/* {relatedWork && (
          <WorkRelated allWork={relatedWork} slugToExclude={null} max={2} />
        )} */}
      </section>
      </Layout>
    )
  }
}

export default NewsSingleComponent
